@import "size";

.sideright {
  @include sideright;
}

#basket {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 10vh;
  height: 100vh;
}

.basket-title{
  margin-top: 3vh;
  margin-bottom: 5vh;
}

.basket-sub-total-container{
  margin-top: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
//
// .basket_element_container_main{
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   img {
//     width: 100px;
//     height: 100px;
//     object-fit: cover;
//   }
// }

.product-short{
  font-weight: bold;
}
//
// .basket_element_container{
//   display: flex;
//   align-items: baseline;
//   justify-content: space-between;
//   width: 95%;
//   margin-left: 5%;
// }
//
// .basket_vertical_container{
//   flex-grow: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// }

input[type="number"]:not(.browser-default).size_rep_element_input{
  -moz-appearance: textfield;

  max-width: 70px;
  min-width: 30px;

  ::-webkit-inner-spin-button ,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.basket_line_container{
  margin-bottom: 20px;
  .basket_row{
    display: flex;
    .fixe-width{
      width: 100px;
      height: 125px;
    }
    .auto-width{
      flex-grow: 4;
    }
  }

  .basket_line_infos{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    .basket_line_infos_title{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .size_rep_flex{
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    max-width: 250px;
  }

  img{
    object-fit: cover;
  }

  .row .col {
    padding: 0 2px;
  }
  .price {
    align-self: flex-end;
  }
}
