@import "../aramisColor";
@import "../size";

.wcentprcent {
  width: 100%;
}

.promotion {
  z-index: 1;
  position: relative;
  max-width: 0;
  overflow-y: hidden;
  transition: all 2,7s ease-in;
}

.promotion.currentPromotion {
  max-width: 100vw;
}


.promotion-text{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 20%;
  z-index: 1;
  color: white;
  margin-bottom: 9vw;
}

.promotion-phrase{
  font-size: 2vw;
  font-weight: bold;
  margin-left: 7px;
}

.promotion-title{
  font-size: 9vw;
  font-weight: bold;
  color: $bleu-aramis;
}

@media #{$small-and-down}{
  .promotion-text{
    left: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .promotion-phrase{
    font-size: 4vw;
  }
  .promotion-title{
    font-size: 15vw;
  }
}

.promotion-link{
  font-size: 1vw;
  margin-left: 7px;
}



#promotions{
  position: relative;
  z-index: 2;
}

#indicators {
  height: 9vw;
  position: absolute;
  bottom: 0px;
  color: white;
  width: 100%;
  z-index: 3;

  .indicator {
    margin-right: 10vw;
    font-size: 1vw;

    .progress{
      background-color: white;
      width: 20%;
      min-width: 20%; //stored for js part
      height: 1px;
    }

    p{
      cursor: default;
    }
  }
}

#promotionsOnSmall{
  .carousel{
    height: 150px;
  }
  .carousel-item{
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 150px;
    .flex-container{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 150px;
      background-color: rgba(0,0,0,0.3);
    }
    .flex-container-horizontal{
      display: flex;
      align-items: space-around;
      flex-direction: row;
      height: 50px;
    }
  }
}
