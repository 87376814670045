@import "aramisColor";
@import "size";


.footer-container {
  margin: 0 auto;
  width: 90%;
}
//
// @media only screen and (min-width: $small-screen-up) {
//   /* line 7, /usr/local/var/rbenv/versions/2.5.3/lib/ruby/gems/2.5.0/gems/materialize-sass-1.0.0/assets/stylesheets/materialize/components/_grid.scss */
//   .container {
//     width: 85%;
//   }
// }
// @media only screen and (min-width: $medium-screen-up) {
//   /* line 12, /usr/local/var/rbenv/versions/2.5.3/lib/ruby/gems/2.5.0/gems/materialize-sass-1.0.0/assets/stylesheets/materialize/components/_grid.scss */
//   .container {
//     width: 70%;
//   }
// }

footer{
  h1,h2,h3,h4,h5,h6 {
    font-family: FuturaPTBook;
  }

  .devise-langue {
    display: flex;
    justify-content: flex-end;
    .input-field {
      margin: 0rem 2rem;
    }

    input:not([type]), input[type=text]:not(.browser-default),
    input[type=password]:not(.browser-default),
    input[type=email]:not(.browser-default),
    input[type=url]:not(.browser-default),
    input[type=time]:not(.browser-default),
    input[type=date]:not(.browser-default),
    input[type=datetime]:not(.browser-default),
    input[type=datetime-local]:not(.browser-default),
    input[type=tel]:not(.browser-default),
    input[type=number]:not(.browser-default),
    input[type=search]:not(.browser-default),
    textarea.materialize-textarea {
      color: $fauxBlanc;
    }
    .select-wrapper .caret {
      fill: $fauxBlanc;
    }
  }

  label {
    font-size: 1rem;
    color: $fauxBlanc;
  }

  a {
    color: $fauxBlanc;
    //text-overflow: ellipsis;
    white-space: nowrap;
    overflow: scroll;
    // overflow: hidden;
    line-height: 35px;

    @media #{$small-and-down} {
      margin-right: 10px;
    }

    @media #{$medium-and-up} {
      margin-right: 40px;
    }
  }

  .align-height-on-input {
    line-height: 54px;
  }

  h1,h2,h3{
    letter-spacing: 1px;
  }

  .page-footer {
    padding-top: 85px;
  }

  input[type=email].invalid:not(.browser-default),{
    color: red;
  }

  input.valid:focus,
  input[type=email]:not(.browser-default),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=email].valid:not(.browser-default),
  textarea.materialize-textarea {
    padding-left: 17px;
    border-bottom: 1px solid white;
    box-shadow: none;
    color: white;
  }

  .input-field  .helper-text{
    font-size: large;
  }

  .input-field .postfix {
    position: absolute;
    font-size: 2rem;
    transition: color .2s;
    top: 0.5rem;
    right: 0rem;
    color: $gris-bleu;
    //display: true;
  }

  .input-field .postfix.active {
    color: white;
    display: block;
  }

  .input-field > label {
    padding-left: 20px;
  }

  input[type=email]:not(.browser-default):focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: white;
  }
}
