@import "../size";
@import "../aramisColor";

.product-selection:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  img{
    object-fit: cover;
  }

  .btn-on-card {
    visibility: visible;
  }
}

.product-selection{
  box-shadow: none;
  margin-top: 2vw;

  h6, a {
    margin-left: 1,5vw;
    color: black;
  }

  .btn {
    padding: 0;
  }

  img{
    @include selection-img;
    object-fit: contain;
  }

  .label {
    position: absolute;
    z-index: 100;
    background-color: $bleu-aramis;
    color: white;
    padding-left: 2%;
    padding-right: 10%;
    margin-top: 5%;
  }

  .promo {
    position: absolute;
    z-index: 100;
    background-color: $jaune-promo;
    color: white;
    padding-left: 2%;
    padding-right: 15%;
    margin-top: 5%;
  }

  .btn-on-card{
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: space-between;
  }

  .btn-basket{
    flex-grow: 4;
    text-align: left;
  }
  .btn-right{
    margin-right: 1vw;
  }
}

.product-selection-title{
  .h1-debut{
    margin: 2.8rem 0 0 0;
  }

  .h1-fin{
    margin: 0 0 1.68rem 0;
  }
}

#products-selection {
  @media #{$small-and-down}{
    padding-top: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }

  @media #{$medium-and-up}{
    padding-top: 0vw;
    padding-right: 8vw;
    padding-left: 8vw;
  }

}

@media #{$small-and-down}{
  .carousel-selection{
    height: 200px;
  }

  .card .card-content {
    padding: 0px 24px 10px 24px;
  }

  .carousel .carousel-item > img {
    height: 70%;
  }
  .product-selection .btn-right {
    margin-right: 0vw;
    margin-left: 7vw;
  }
}

.end-selection {
   display: flex;
   justify-content: center;
   margin-bottom: 2vh;
}

.carousel-selection{
  height: 253px;
}
