@import "javascript/stylesheets/size";

#conditions {
  img {
    height: 60px;
    object-fit: contain;
    width: 80%;
    margin: 10%;
  }


  @media #{$large-and-up} {
    .lmargin{
      padding-left: 5%;
      padding-right: 5%;
    }
  }


  .condition-container {
    color: white;
    background-image: url("/images/home_bandeau.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;

    #title-container{
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;

      h6, h5, h2 {
        text-align: center;
      }

      h5{
        font-size: small;
      }

      h2 {
        font-size: large;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
      }

      h6 {
        font-size: small;
      }
    }
  }
}
