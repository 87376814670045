// @import "aramisColor";
// @import "size";
@import "~materialize-css/sass/components/tabs";
@import "navbar/search";

nav {
  @include nav-size;
  z-index: 800;
  width: 100vw;
}

#snt {
  padding: 0px;
}

nav a {
  color: $faux-noir !important;
  //letter-spacing: 0.1em;
  font-family: FuturaPTMedium;
}

nav a:hover {
  border-bottom: 3px solid $bleu-aramis;
}

nav ul a:hover {
  background-color: rgba(0,0,0,0);
}

nav .select {
  border-bottom: 3px solid $gris-bleu;
}

#middleToolLi {
  width: 0px;
  color: transparent !important;
}

.hideBottomLink {
  border-bottom: none !important;
}

.nav-filter {
  display: block;
  width: 100%;
  background-color: $bleu-aramis;
  line-height: 300%;
  padding-top: 30px;
  padding-bottom: 50px;
  color: white;
}

.select {
  font-weight: bold;
  text-decoration: underline white;
  cursor: default;
  color: white;
}

.categorie-mobile {
  padding-top: 10px;
  padding-bottom: 20px;
}

// .nav-filter {
//   .select {
//     font-weight: bold;
//     text-decoration: underline $gris-bleu;
//     cursor: default;
//   }
// }

.modal {
  .pagination{
    margin-top: 0px;
  }
  li a {
    color: $unselect-white;
    .select {
      font-weight: bold;
      text-decoration: underline white;
      color: white;
    }
  }
  .tabs {
    background-color: $bleu-aramis;
  }
  .categorie-mobile{
    .btn-flat{
      color: $unselect-white;
    }
  }
}



.navbarFixed {
  position: fixed;
}

#subCatNav {
  @include nav-top;
  z-index: 800;
  max-height: 0;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  line-height: 0%;
  transition: all 0.7s ease;
  background-color: white;
}

#subCatNav.open{
  padding-top: 30px;
  padding-bottom: 50px;
  line-height: 300%;
  max-height: 100vh;
  background-color: $bleu-aramis;
}

.categorie {
  color: white;
}

.nav-wrapper{
  display: flex;
}
#nav-logo-and-search {
  flex-grow: 90;
  display: inline-flex;
  overflow: hidden;
}
#nav-empty{
  flex-grow: 100;
}
#nav-link{
  overflow: hidden;
}
#navbarSearchLi{
  flex-grow: 100;
  overflow: hidden;
}
#navbarSearch {
  flex-grow: 100;
  overflow: hidden;
}

.nav-icon{
  @include nav-icon();
}

.fixed-bottom{
  position: sticky;
  width: 100%;
  bottom: 0px;
  background-color: white;
  z-index: 100;
}

nav .mbadge{
  top: 30px;
  position: absolute;
  line-height: 20px;
  background-color: black;
  border-radius: 25px;
  margin-left: 10px;
  text-align: center;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.closer{
  margin-right: -20px;
}

#menu_more a:hover {
  border-bottom: none;
}

#subCatNavClose {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
