.btn-full-width{
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.btn-outline{
  outline: 1px solid black;
  color: black;
}

i {
  cursor: pointer;
}
