@import "../aramisColor";
@import "../size.scss";

#navbarSearchFilterShow {
  right: 45px;
}

#navbarSearchForm {
  width: 100%;
  @media #{$small-and-down}{
    input[type=search] {
      padding-left: 1rem;
    }

    .label-icon{
      display: none;
    }
    #searchInput {
      height: 51px;
    }
  }
}

#searchInput {
  font-size: x-large;
  height: 60px;
  color: $bleu-aramis;
}

.input-field input[type=search] ~ .mdi-navigation-close, .input-field input[type=search] ~ .material-icons {
    color: $bleu-aramis;
}

nav .input-field label i {
    color: $bleu-aramis;
}

.input-field input[type=search]:focus:not(.browser-default) + label i, .input-field input[type=search]:focus:not(.browser-default) ~ .mdi-navigation-close, .input-field input[type=search]:focus:not(.browser-default) ~ .material-icons {
    color: $bleu-aramis;
}

#subSearch.open{
  max-height: 200vh;
}

#subSearch {
  z-index: 700;
  @include under-nav;
  background-color: transparent;
  color: $gris-bleu;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.5s ease;

  @media #{$small-and-down}{
    flex-direction: column;
  }

  .row {
    margin-bottom: 0px;
  }

  #suggestions{
    @media #{$small-and-down}{
      width: 100%;
      max-height: 20vh;
    }
    @media #{$medium-only}{
      width: 30%;
    }
    @media #{$large-and-up}{
      width: 20%;
    }
    padding-right: 3%;
    padding-left: 3%;
    @media #{$medium-and-up}{
      padding-top: 3%;
      padding-bottom: 3%;
    }

    overflow-x: scroll;

    .col {
      line-height: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    a.complete-search-suggestion{
      font-size: large;
      white-space: pre-wrap;
      line-height: 100%;
      background-color: $gray-bg;
    }

    .rapprocheMoi {
      margin-left: -3px;
    }
  }

  #searchResultProduct{
    background: white;
    @media #{$small-and-down}{
      width: 100%;
      max-height: 70vh;
    }
    @media #{$medium-only}{
      width: 70%;
    }
    @media #{$large-and-up}{
      width: 40%;
    }


    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;

    h6 {
      font-size: 0.9rem;
      line-height: 200%;
      margin-top: 3%;
      margin-bottom: 3%;
    }

    #searchResultProductContent {
      overflow-x: scroll;
      margin: 0;
      padding: 0;
      .product-result {
        height: 100px;
        display: flex;
        margin-bottom: 20px;
        img {
          height: 100px;
          width: 80px;
          object-fit: contain;
        }

        .text-part{
          display: flex;
          flex-direction: column;
          height: 100px;
          justify-content: space-evenly;
          p {
            margin: 0 0 0 10px;
            line-height: 100%;

          }
        }
      }
    }
    .allProducts {
      text-align: end;
    }


  }

  #searchResultArticle {
    background: white;
    @media #{$large-and-up}{
      width: 40%;
    }

    display: flex;
    flex-direction: row;
    .article_result {
      padding-top: 15%;
      text-align: center;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;

      button {
        width: 50%;
      }
    }
  }
  #voileNoirForSubSearch {
    flex-grow: 1;
  }
}
