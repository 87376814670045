$bleu-aramis: #092F99;
$jaune-promo: #F5A623;
$faux-noir: #070916;
$gris-bleu: #9EA0A0;
$lienJaune: #FFF5A3;
$gris-desactiver: #838889;
$gray-bg: #F7F7F7;
$unselect-white: rgba(255, 255, 255, 0.7);
//F7F7F7
$fauxBlanc: rgba(255, 255, 255, 0.5);
$primary-color: white !default;

$secondary-color: $bleu-aramis !default;

.gris-desactiver {
  color: $gris-desactiver;
}

.fauxNoir {
  color: $faux-noir;
}

.fauxBlanc {
  color: $fauxBlanc;
}

.bleu-aramis {
  color: $bleu-aramis;
}

.bleu-aramis-important {
  color: $bleu-aramis !important;
}

.unselect-white {
  color: $unselect-white;
}

.gray-bg {
  background-color: $gray-bg;
}

.btn-floating.gray-bg:hover{
  background-color: $gray-bg;
}

.gris-bleu {
  color: $gris-bleu;
  background-color: white;
}

.gris-bleu {
  .btn, .btn-large, .btn-small{
    color: $gris-bleu;
    background-color: white;
  }
  .btn:hover, .btn-large:hover, .btn-small:hover{
    color: $gris-bleu;
    background-color: white;
  }
}


.bleu-aramis-bg {
  background-color: $bleu-aramis !important;
  color: white;
  h1,h2,h3,h4,h5,h6 {
    color: white;
  }
}
