@import "javascript/stylesheets/aramisColor";
@import 'javascript/stylesheets/size';

#articles {
  padding-right: 8vw;
  padding-left: 8vw;
  margin-top: 4vw;
  height: 61vw;

  .article {
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      top: 3vw;
      font-size: 11px;
    }

    h2 {
      position: absolute;
      font-size: large;
      top: 2vw;
    }

    h2.textArticleCenter {
      top: 17%;
    }

    span.textArticleCenter {
      top: 20%;
    }

    .btn {
      position: absolute;
      top: 10vw;
      font-size: x-small;
    }

    .btn.textArticleCenter {
      top: 55%;
      width: 40%;
      margin-left: 25%;
      margin-right: 30%;
    }

    .btn:hover{
      background-color: white !important;
      color: $faux-noir;
      outline: none;
    }

    .textArticle {
      left: 4vw;
      color: white;
    }

    .textArticleCenter {
      left: 5%;
      text-align: center;
      width: 90%;
    }
  }

  #article1 {
    height: 40vw;
    padding-bottom: 1vw;
  }

  #article2 {
    // width: 28vw;
    height: 30vw;
    padding-bottom: 1vw;
  }

  #article3 {
    // width: 28vw;
    height: 30vw;
  }

  #abonnement {
    height: 20vw;
    border: 1px solid $bleu-aramis;
    padding: 2vw;

    h4 {
      color: $bleu-aramis;
      font-size: large;
      @media #{$medium-and-down}{
        margin-top: 0;
      }
    }

    .btn {
      font-size: x-small;
    }

    span {
      font-size: x-small;
    }
    // padding-right: 1vw;
  }

  #emailAndButton {
    display: flex;
    justify-content: space-between;

    margin-top: 1vw;

    input {
      flex-grow: auto;
      margin-right: 2vw;
      border: 1px solid $bleu-aramis;
      font-size: small;
      padding-left: 20px;
    }

    input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $gris-bleu;
    }
    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $gris-bleu;
      opacity: 1;
    }
    input::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $gris-bleu;
      opacity: 1;
    }
    input:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $gris-bleu;
    }
    input::-ms-input-placeholder { /* Microsoft Edge */
      color: $gris-bleu;
    }
    input::placeholder { /* Most modern browsers support this now. */
      color: $gris-bleu;
    }

    .btn {
      height: 43px;
      line-height: 43px;
      width: 30%;
    }
  }
}

#articlesOnSmall{
  .carousel-item{
    background-repeat: no-repeat;
    background-size: cover;

    .flex-container{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      background-color: rgba(0,0,0,0.3);
    }
    .textArticleCarousel{
    }
  }
}
