.user-info-collection {
  .collection {
    border: none;
    .collection-item.avatar {
      min-height: 40px;
      border-bottom: none;
    }
    .collection-item {
      line-height: 2.5rem;
    }
  }
}
