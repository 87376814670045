$small-screen-up: 601px !default;
$medium-screen-up: 1201px !default;
$large-screen-up: 1801px !default;
$small-screen: 600px !default;
$medium-screen: 1200px !default;
$large-screen: 1800px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

@mixin sideright() {
  @media #{$small-and-down} {
    width: 99vw;
  }
  @media #{$medium-only}{
    width: 60vw;
  }
  @media #{$large-and-up} {
    width: 40vw;
  }
  @media #{$extra-large-and-up} {
    width: 30vw;
  }
}

@mixin selection-img(){
  @media #{$small-and-down}{
    height: 60vw;
  }

  @media #{$medium-and-up}{
    height: 20vw;
  }
}

@mixin font-size-bigger() {
  @media #{$small-and-down} {
    font-size: 10.0vw;
  }
  @media #{$medium-only}{
    font-size: 2.5vw;
  }
  @media #{$large-and-up} {
    font-size: 2.0vw;
  }
  @media #{$extra-large-and-up}{
    font-size: 1.0vw;
  }
}

@mixin font-size-big() {
  @media #{$small-and-down} {
    font-size: 8.0vw;
  }
  @media #{$medium-only}{
    font-size: 2.0vw;
  }
  @media #{$large-and-up} {
    font-size: 1.5vw;
  }
  @media #{$extra-large-and-up}{
    font-size: 0.9vw;
  }
}

@mixin mbadge() {
  @media #{$small-and-down} {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 17px;
    position: relative;
    top: 16px;
    float: right;
  }
  @media #{$medium-only}{
    padding-left: 0.7vw;
    padding-right: 0.7vw;
  }
  @media #{$large-and-up} {
    padding-left: 0.55vw;
    padding-right: 0.55vw;
  }
  @media #{$extra-large-and-up}{
    padding-left: 0.3vw;
    padding-right: 0.3vw;
  }
}

@mixin nav-size() {
  @media #{$large-and-up} {
    padding-left: 60px;
    padding-right: 60px;
    height: 102px;
    padding-top: 20px;
  }
  @media #{$medium-only}{
    padding-left: 20px;
    padding-right: 20px;
    height: 62px;
    padding-top: 6px;
  }

  @media #{$small-and-down} {
    padding-left: 5px;
    padding-right: 5px;
    height: 62px;
    padding-top: 6px;
  }
}

@mixin nav-icon() {
  @media #{$large-and-up} {
    height: 64px;
  }
  @media #{$medium-and-down}{
    height: 50px;
  }
}

@mixin suggestion() {
  @media #{$large-and-up} {
    top: 67px;
    left: 260px;
  }
  @media #{$medium-and-down}{
    top: 45px;
    left: 135px;
  }
  @media #{$small-and-down} {
    left: 78px;
  }

}

@mixin nav-top() {
  @media #{$large-and-up} {
    top: 102px;
  }
  @media #{$medium-and-down}{
    top: 62px;
  }
}

@mixin under-nav() {
  @media #{$large-and-up} {
    padding-top: 102px;
  }
  @media #{$medium-and-down}{
    padding-top: 62px;
  }
}

@mixin on-nav() {
  @media #{$large-and-up} {
    top: 30px;
  }
  @media #{$medium-and-down}{
    top: 10px;
  }
}

@mixin on-or-under-nav() {
  @media #{$large-and-up} {
    top: 110px;
  }
  @media #{$medium-and-down}{
    top: 10px;
  }
}
