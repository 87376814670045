@charset "UTF-8";

// Color
@import "~materialize-css/sass/components/color-variables";
@import "~materialize-css/sass/components/color-classes";
@import "aramisColor";
//$border-dark: rgba($base-color, 0.88);

// Variables;
@import "~materialize-css/sass/components/variables";

// Reset
@import "~materialize-css/sass/components/normalize";

// materialize-css/sass/components
@import "~materialize-css/sass/components/global";
@import "~materialize-css/sass/components/navbar";
@import "~materialize-css/sass/components/grid";
@import "~materialize-css/sass/components/cards";
@import "~materialize-css/sass/components/toast";
@import "~materialize-css/sass/components/buttons";
@import "~materialize-css/sass/components/waves";
@import "~materialize-css/sass/components/dropdown";
@import "~materialize-css/sass/components/modal";
@import "~materialize-css/sass/components/carousel";
@import "~materialize-css/sass/components/sidenav";
@import "~materialize-css/sass/components/forms/forms";
@import "~materialize-css/sass/components/typography";

// @import "materialize-css/sass/components/badges";
// @import "materialize-css/sass/components/icons-material-design";
// @import "materialize-css/sass/components/transitions";
// @import "materialize-css/sass/components/tooltip";
// @import "materialize-css/sass/components/collapsible";
//
// @import "materialize-css/sass/components/materialbox";
// @import "materialize-css/sass/components/table_of_contents";
// @import "materialize-css/sass/components/preloader";
// @import "materialize-css/sass/components/slider";
// @import "materialize-css/sass/components/tapTarget";
// @import "materialize-css/sass/components/pulse";
// @import "materialize-css/sass/components/datepicker";
// @import "materialize-css/sass/components/timepicker";

@import "button";
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
@import "navbar";
@import "footer";
@import "basket";


//@import "/\*";


#bodyContentUnderNavbar {
  @include under-nav;
}

body {
  width: 100vw;
}

@font-face {
  font-family: FuturaPTBook;
  src: url("/fonts/FuturaPTBook.otf");
}

@font-face {
  font-family: FuturaPTMedium;
  src: url("/fonts/FuturaPTMedium.otf");
}
html {
  font-family: FuturaPTBook;
}

.full-width {
  width: 100%;
  display: block;
}

.bottom-padding {
  padding-bottom: 40px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
}

.noscrollbar::-webkit-scrollbar {
    display: none;
}

.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#bigtoast {
  max-height: 89%;
  width: 96%;
  height: 96%;
  background-color: white;
}

#confirmtoast {
  background-color: white;
  .modal-content{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.sticky-on-navbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  @include on-or-under-nav();
  z-index: 800;
  background-color: white;
}


.container-sticky {
  max-width: 1280px;
}

@media #{$small-and-down} {
  .container-sticky {
    padding-left: 5%;
    padding-right: 5%;
    label {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media #{$medium-and-up} {
  .container-sticky {
    padding-left: 8%;
    padding-right: 8%;
    label {
      padding-left: 8%;
      padding-right: 8%;
    }
  }
}
@media #{$large-and-up} {
  .container-sticky {
    width: 70%;
    padding-left: 0%;
    padding-right: 0%;
    margin-left: auto;
    margin-right: auto;
    label {
      width: 70%;
      padding-left: 0%;
      padding-right: 0%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.modal-page {
  background-color: white;
  width: 100%;
  max-height: 100%;
  height: 100% !important;
}

.rotate-sync-animation {
  transform: rotate(0deg);
  transition: all 1s;
  animation-delay: 1s;
  animation: 1600ms fast-rotate infinite ease-in-out;
}

@keyframes fast-rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-1080deg); }
}

.stop-sync-animation {
  transform: scale(1);
  transition: all 1s;
  animation-delay: 1s;
  animation: 1600ms pulsate infinite alternate ease-in-out;
}

@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.btn-outline {
  outline: 1px solid;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

a {
  .btn-outline {
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    font-size: 14px;
  }
}

@media #{$small-and-down}{
  .row{
    margin-bottom: 0px;
  }
}

.startHide {
  display: none;
}

.btn-flat:hover {
  background-color: transparent;
}

.lowercase { text-transform: lowercase; }
.uppercase { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }

.title-with-button-on-right-container{
  display: flex;
  align-items: baseline;
}

.title-with-button-on-right{
  flex-grow: 1;
}

.fixed-action-btn-return{
  position: fixed;
  top: 23px;
  left: 23px;
}

.biggerh3{
  font-size: 5rem;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
  align-items: center;
}

.bold {
  font-weight: bold;
}

input[type="text"].valid:not(.browser-default),
input[type="text"].valid:not(.browser-default):focus,
input[type="password"].valid:not(.browser-default),
input[type="password"].valid:not(.browser-default):focus,
input[type="email"].valid:not(.browser-default),
input[type="email"].valid:not(.browser-default):focus,
input[type="url"].valid:not(.browser-default),
input[type="url"].valid:not(.browser-default):focus,
input[type="time"].valid:not(.browser-default),
input[type="time"].valid:not(.browser-default):focus,
input[type="date"].valid:not(.browser-default),
input[type="date"].valid:not(.browser-default):focus,
input[type="datetime"].valid:not(.browser-default),
input[type="datetime"].valid:not(.browser-default):focus,
input[type="datetime-local"].valid:not(.browser-default),
input[type="datetime-local"].valid:not(.browser-default):focus,
input[type="tel"].valid:not(.browser-default),
input[type="tel"].valid:not(.browser-default):focus,
input[type="number"].valid:not(.browser-default),
input[type="number"].valid:not(.browser-default):focus,
input[type="search"].valid:not(.browser-default),
input[type="search"].valid:not(.browser-default):focus,
textarea.materialize-css sass-textarea.valid,
textarea.materialize-css sass-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid $bleu-aramis;
  box-shadow: 0 1px 0 0 $bleu-aramis;
}

input:not([type]):focus.valid ~ label,
input[type="text"]:not(.browser-default):focus.valid ~ label,
input[type="password"]:not(.browser-default):focus.valid ~ label,
input[type="email"]:not(.browser-default):focus.valid ~ label,
input[type="url"]:not(.browser-default):focus.valid ~ label,
input[type="time"]:not(.browser-default):focus.valid ~ label,
input[type="date"]:not(.browser-default):focus.valid ~ label,
input[type="datetime"]:not(.browser-default):focus.valid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.valid ~ label,
input[type="tel"]:not(.browser-default):focus.valid ~ label,
input[type="number"]:not(.browser-default):focus.valid ~ label,
input[type="search"]:not(.browser-default):focus.valid ~ label,
textarea.materialize-css sass-textarea:focus.valid ~ label {
  color: $bleu-aramis;
}

input.valid:not([type]),
input.valid:not([type]):focus,
input[type="text"].valid:not(.browser-default),
input[type="text"].valid:not(.browser-default):focus,
input[type="password"].valid:not(.browser-default),
input[type="password"].valid:not(.browser-default):focus,
input[type="email"].valid:not(.browser-default),
input[type="email"].valid:not(.browser-default):focus,
input[type="url"].valid:not(.browser-default),
input[type="url"].valid:not(.browser-default):focus,
input[type="time"].valid:not(.browser-default),
input[type="time"].valid:not(.browser-default):focus,
input[type="date"].valid:not(.browser-default),
input[type="date"].valid:not(.browser-default):focus,
input[type="datetime"].valid:not(.browser-default),
input[type="datetime"].valid:not(.browser-default):focus,
input[type="datetime-local"].valid:not(.browser-default),
input[type="datetime-local"].valid:not(.browser-default):focus,
input[type="tel"].valid:not(.browser-default),
input[type="tel"].valid:not(.browser-default):focus,
input[type="number"].valid:not(.browser-default),
input[type="number"].valid:not(.browser-default):focus,
input[type="search"].valid:not(.browser-default),
input[type="search"].valid:not(.browser-default):focus,
textarea.materialize-css sass-textarea.valid,
textarea.materialize-css sass-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid $bleu-aramis;
  box-shadow: 0 1px 0 0 $bleu-aramis;
}


.collection.no-border{
  border: 1px solid transparent;
  .collection-item {
    border-bottom: none;
  }
}

.collection.no-border.fixedFor5{
  min-height: 279px;
}

.voileNoir {
  width: 100vw;
  height: 100vh;
  background: #000000bf;
}
