@import "../aramisColor";

#annonceRow {
  background-color: $bleu-aramis;
  height: 43px;
  color: white;
  margin-bottom: 0px;

  #annonceMessage{
    padding-right: 20px;
  }
}

#annonceColumn{
  background-color: $bleu-aramis;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0px;

  #annonceMessageTitle{
    font-size: xx-large;
    font-family: FuturaPTMedium;
  }

  #annonceMessage{
    font-size: xx-large;
    font-family: FuturaPTBook;
  }

  #annonceMessageDeux{
    font-size: xx-large;
    font-family: FuturaPTMedium;
  }

  #annonceMessageLink{
    font-size: smaller;
  }
}


#annonceMessageLink{
  font-size: 13px;
  color: $lienJaune !important;
}
