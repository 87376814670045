@import "../size";
@import "../aramisColor";

.product-promotion {
  width: 25vw;
  height: 20vw;
  margin-top: 1vw;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 1vw;

  img {
    z-index: 99;
    position: relative;
    top: 0;
    height: 20vw;
    object-fit: cover;
    width: 25vw;
  }

  p {
    z-index: 100;
    position: relative;
    top: -22vw;
    left: 3vw;
    font-size: 3vw;
    color: $bleu-aramis;
    width: 24vw;

    overflow-y: hidden;
    text-overflow: ellipsis;
    height: 5vw;
  }

  span {
    z-index: 100;
    text-decoration: underline;
    position: relative;
    top: -25vw;
    left: 3vw;
    font-size: 1vw;
    color: $bleu-aramis;
    width: 24vw;
    font-size: small;
  }
}

#products-promotions {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 8vw;
  padding-left: 8vw;
}

@media #{$small-and-down} {
  #products-promotions {
    .product-promotion {
      display: inline-flex;
      background-size: cover;
      background-repeat: no-repeat;
      padding-left: 2vw;

      p {
        display: contents;
      }
    }
  }
}
